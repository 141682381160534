import React from "react";
import Layout from "../../components/Layout";
import { navigate } from "gatsby";
import Button from 'react-bootstrap/Button';
import { Helmet } from "react-helmet";

// eslint-disable-next-line
export default () => (
  <Layout>
    <Helmet>
      <title>Thank You</title>
      <meta name="robots" content="noindex"></meta>
    </Helmet>
    <article className="page page--thank-you">
      <section className="container-md">
        <div className="content col-md-6 mx-auto">
          <h1>Thank you!</h1>
          <p>Your message has been sent.</p>

          <Button variant="outline-dark" onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </div>
      </section>
    </article>
  </Layout>
);
